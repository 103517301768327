.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-wrapper .images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react-slideshow-wrapper .images-wrap * {
  max-width: 100%;
}

.react-slideshow-fade-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap * {
  max-width: 100%;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
  position: relative;
  opacity: 0;
}

.react-slideshow-zoom-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper * {
  max-width: 100%;
}

.react-slideshow-zoom-wrapper .zoom-wrapper > div {
  position: relative;
  display: flex;
}

.react-slideshow-container {
  display: flex;
  align-items: center;
}

.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-slideshow-container .nav img {
  width: 60%;
}

.react-slideshow-container .nav:hover {
  background: white;
  color: #666;
}

.react-slideshow-container .nav:first-of-type {
  margin-right: -30px;
}

.react-slideshow-container .nav:last-of-type {
  margin-left: -30px;
}

.react-slideshow-container + div.indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-slideshow-container + div.indicators > div {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div:hover {
  background: #666;
}

.react-slideshow-container + div.indicators > div.active {
  background: #000;
}

.react-slideshow-container + div.indicators > div:last-of-type {
  margin-right: 0;
}

